import { GlobalStateContext } from '../App'
import BlockModelPresentation from './BlockModelPresentation'

import { useState, useEffect, useContext } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import ReactPlayer from 'react-player/file'
// import { IoMdConstruct } from 'react-icons/io'

import { isInstagram } from './Global'
import MainConfiguratorBlock from './BlockConfigurator'
import BlockPersonalization from './BlockPersonalization'
import { getUiSpecTree } from './_uiHelpers'
import { DESIGNER_URL } from './__urlsSystem'
import { unabbreviateParams } from './_makeUrls'
import ConfigModal from './ModalBasic'
import { logSession } from './_logging'
import { localize } from './_localization'

export default function Configurator({ omitBanner, omitAR, omitUpDown }) {
	const { globalState, setGlobalStateItem } = useContext(GlobalStateContext)

	const { productId } = useParams()
	const [searchParams] = useSearchParams()
	const incomingParamsEncoded = Object.fromEntries(new URLSearchParams(searchParams)) // convert to an object (flat list of key:value pairs)
	// console.log('Config..Block', productId, incomingParamsEncoded)

	const [uiTree, setUiTree] = useState()
	const [mainConfiguratorBlock, setMainConfiguratorBlock] = useState()
	const [personalizationBlock, setPersonalizationBlock] = useState()

	// intro notes and warnings
	const [showWelcomeNote, setShowWelcomeNote] = useState(false)
	const [showInstagramNote, setShowInstagramNote] = useState(false)
	// stuff related to LandscapeRec, the recommendation to switch to landscape orientation
	const [showLandscapeRec, setShowLandscapeRec] = useState(false)
	const [pauseLandscapeCheck, setPauseLandscapeCheck] = useState(true) // don't check for landscape until intro notes are started
	const [hasSeenLandscapeRec, setHasSeenLandscapeRec] = useState(false) // show LandscapeRec only once during a session

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	useEffect(() => {
		// console.log('Config..1', ucProductId)
		setGlobalStateItem('productId', productId)
		setUiTree(getUiSpecTree(productId)) // get the spec for the parameter adjusters for this productId (and get the friendly names)
		logSession(productId)
		// eslint-disable-next-line
	}, [productId])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// set the major blocks in useEffect's so they don't get re-rendered on other global state changes
	useEffect(() => {
		if (uiTree === undefined) return
		setPersonalizationBlock(
			<BlockPersonalization
				uiTree={uiTree}
				productId={productId}
				incomingParams={unabbreviateParams(productId, incomingParamsEncoded)}
			/>
		)
		// eslint-disable-next-line
	}, [uiTree, productId])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	useEffect(() => {
		setMainConfiguratorBlock(<MainConfiguratorBlock productId={productId} adjustCenter={true} />)
	}, [uiTree, productId])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// show the welcome popup upon initial load after a short delay
	useEffect(() => {
		const timer = setTimeout(() => {
			isInstagram ? setShowInstagramNote(true) : setShowWelcomeNote(true)
			setPauseLandscapeCheck(false) // don't check for landscape until these notes get started
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//check for landscape orientation and then setShowLandscapeRec, but not until the intro notes have started and been dismissed
	useEffect(() => {
		if (globalState?.windowProps === undefined || pauseLandscapeCheck || showWelcomeNote || isInstagram) return
		if (hasSeenLandscapeRec) return // don't show it again during this session
		const landscape = globalState.windowProps.filter((kvpair) => kvpair[0] === 'landscape')[0][1]
		setShowLandscapeRec(!landscape)
		setHasSeenLandscapeRec(true)
	}, [globalState, pauseLandscapeCheck, showWelcomeNote, hasSeenLandscapeRec])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// if LandscapeRec is showing, turn it off after a delay of 10 seconds (which allows the fade in/out in styles.css to complete)
	useEffect(() => {
		if (!showLandscapeRec) return
		const timer = setTimeout(() => {
			setShowLandscapeRec(false)
		}, 10 * 1000)
		return () => clearTimeout(timer)
	}, [showLandscapeRec])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

	const Video = () => {
		// console.log(DESIGNER_URL + '/videos/' + globalState.videoFilename)
		return (
			<div>
				<VideoTitle>{globalState.videoTitle}</VideoTitle>
				<ReactPlayer
					url={DESIGNER_URL + '/videos/' + globalState.videoFilename}
					playing={true}
					controls={true}
					loop={true}
					muted={true}
					playsinline={true}
					onReady={() => true}
					width='100%'
					height='100%'
					config={{
						file: {
							attributes: {
								controlsList: 'nofullscreen',
							},
						},
					}}
				/>
			</div>
		)
	}

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	return (
		<BlockModelPresentation omitBanner={omitBanner} omitAR={omitAR} omitUpDown={omitUpDown}>
			<ModelDiv>{mainConfiguratorBlock}</ModelDiv>
			<PersonalizationDiv>{personalizationBlock}</PersonalizationDiv>
			<ConfigModal isOpen={globalState.showVideoModal} onClick={() => setGlobalStateItem('showVideoModal', false)}>
				<VideoDiv>
					<Video />
				</VideoDiv>
			</ConfigModal>
			<ConfigModal isOpen={showWelcomeNote} onClick={() => setShowWelcomeNote(false)}>
				<WelcomeNoteText>
				<p>
						{localize(
							'STRATUM DESIGNER|STRATUM DESIGNER|STRATUM DESIGNER',
							globalState.language
						)}
					</p>
					<p>
						{localize(
							'Your creative playground for designing custom wood furniture tailored to your unique vision!|Salut. Bienvenue dans notre Stratum Designer.|Hallo. Willkommen in unserem Stratum-Designer.',
							globalState.language
						)}
					</p>
					<p>
						{localize(
							'Every creation you design can be brought to life by our expert craftsmen, ensuring a seamless translation from design to finished product.|Vous êtes encouragé à être créatif.|Sie werden ermutigt, kreativ zu sein.',
							globalState.language
						)}
					</p>
					<p>
						{localize(
							'Let\'s have some fun and create your perfect custom wood furniture today!|Tout ce que vous concevez ici, nous pouvons le construire pour vous.|Alles, was Sie hier entwerfen, können wir für Sie bauen.',
							globalState.language
						)}
					</p>
				</WelcomeNoteText>
			</ConfigModal>
			<ConfigModal isOpen={showInstagramNote} onClick={() => setShowInstagramNote(false)}>
				<WelcomeNoteText>
					<p>
						{localize(
							'We notice you are using the Instagram browser.|Nous remarquons que vous utilisez le navigateur Instagram.|Wir stellen fest, dass Sie den Instagram-Browser verwenden.',
							globalState.language
						)}
					</p>
					<p>
						{localize(
							"Stratum Designer works best in landscape orientation, which Instagram does not allow.|Stratum Designer fonctionne mieux en orientation paysage, ce qu'Instagram ne permet pas.|Stratum Designer funktioniert am besten im Querformat, was Instagram nicht zulässt.",
							globalState.language
						)}
					</p>
					<p>
						{localize(
							'We suggest you click the 3 dots in the upper right corner,|Nous vous suggérons de cliquer sur les 3 points dans le coin supérieur droit,|Wir empfehlen Ihnen, auf die 3 Punkte in der oberen rechten Ecke zu klicken,',
							globalState.language
						)}
					</p>
					<p>
						{localize(
							'then choose "Open in Chrome/Safari/Edge"|puis choisissez "Ouvrir dans Chrome/Safari/Edge"|Wählen Sie dann „In Chrome/Safari/Edge öffnen“',
							globalState.language
						)}
					</p>
				</WelcomeNoteText>
			</ConfigModal>
			<LandscapeRec>
				{showLandscapeRec && (
					<div className='fade-landscape-rec'>
						<LandscapeRecText>
							{localize(
								'For the best experience, we recommend that you rotate your device to landscape orientation|Pour une expérience optimale, nous vous recommandons de faire pivoter votre appareil en orientation paysage|Für ein optimales Erlebnis empfehlen wir, dass Sie Ihr Gerät ins Querformat drehen',
								globalState.language
							)}
						</LandscapeRecText>
					</div>
				)}
			</LandscapeRec>
		</BlockModelPresentation>
	)
}

const ModelDiv = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`

const PersonalizationDiv = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	top: ${(props) => props.theme.PersonalizationDiv_top};
	pointer-events: none;
`

const VideoDiv = styled.div`
	position: relative;
	width: ${(props) => props.theme.VideoDiv_width};
	height: ${(props) => props.theme.VideoDiv_height};
`

const VideoTitle = styled.p`
	position: absolute;
	top: ${(props) => props.theme.VideoTitle_top};
	left: 20px;
	right: 20px; // don't cover the close X
	text-align: center;
	// text-transform: capitalize;
`

const WelcomeNoteText = styled.div`
	text-align: center;
`

const LandscapeRec = styled.div`
	width: 200px;
	margin: 80px 0 0 10px;
	color: #b70;
`

const LandscapeRecText = styled.h3``
