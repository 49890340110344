import { computeUrl } from './_makeUrls'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { Box3, Vector3, BufferGeometry, Triangle } from 'three'
import { Text, Cylinder, Sphere } from "@react-three/drei"

import { isModelPlacementWall, cameraProps } from './_uiHelpers'

const gltfLoader = new GLTFLoader()
const dracoLoader = new DRACOLoader()  // allow for DRACO compression
dracoLoader.setDecoderConfig({ type: "js" })
dracoLoader.setDecoderPath(process.env.PUBLIC_URL + '/draco/')
gltfLoader.setDRACOLoader(dracoLoader)

// load the gltf model from Rhino via the middleware server. Returns a promise to be followed by .then()
export function calcModel({ productId, currentParams }) {
	// console.log("calc model", productId, currentParams)
	const url = computeUrl(productId, currentParams)
	return gltfLoader.loadAsync(url.toString())
}

// modify a model for shadow, position, etc.
export function processModel(gltf, productId) {
	if (gltf === undefined) return;

	// cast a shadow on the ground plane
	gltf.scene.traverse((node) => { if (node.isMesh) node.castShadow = true })

	const { frontBottomLeft, backTopRight, center, maxLen } = gltfSize(gltf)

	const cameraTarget = center.clone()

	// the camera is positioned away from the center of the gltf
	// as specified by the height and azimuth properties of the model (e.g. AW, ST, etc.)
	// height and azimuth specify an XYZ position from the center of the gltf. The distance from
	// the center is determined by the maximum length (height or width) of the gltf.
	let { camHtPct, camAzDeg } = cameraProps(productId)
	// camAzDeg represents the desired angle from a head-on view.
	// camHtPct represents the desired view elevation, as a percentage of gltf height.
			// camAzDeg = -30
			// camHtPct = 110
	camAzDeg -= 90 // rotate the normal unit circle so 0 degrees is head-on
	camAzDeg = camAzDeg * Math.PI/180
	const x = Math.cos(camAzDeg)
	const y = Math.sin(camAzDeg)
	const cameraPosition = center.clone()
	cameraPosition.setX(x)
	cameraPosition.setY(backTopRight.y * camHtPct / 100)
	cameraPosition.setZ(-y)
	cameraPosition.multiplyScalar(1.1 * maxLen)

	// check for 'wall' placement, in which case we move the model forward so the shadow plane is at the back
	if (isModelPlacementWall(productId)) {
		gltf.scene.translateZ(-frontBottomLeft.z)  // change Z (which corresponds to Y in GH)
	}

	return { cameraPosition, cameraTarget }
}

const personMaterial = <meshPhongMaterial color={'#ccb'} transparent='true' opacity={0.5} />
const personHeight = 170/100 // 5'7"; males: 5'9" (175cm), females: 5'4" (163cm)
const personHeadRadius = 24/100/2
const personTorsoHeight = personHeight / 2 - personHeadRadius
const personTorsoRadius = 32/100/2
const personLegsRadius = 24/100/2
const personWaistHeight = personHeight / 2

export function contextPerson(gltf, productId, showPerson) {
	if (!showPerson) return null

	const { center, Z } = gltfSize(gltf)
	const location = new Vector3(center.getComponent(0), 0, Z.getComponent(2) - personTorsoRadius)

	return (
		<group position={location}>
			{/* legs */}
			<Cylinder args={[personLegsRadius, personLegsRadius * .8, personWaistHeight, 32]} position={[0, personWaistHeight / 2, 0]}>
				{personMaterial}
			</Cylinder>
			{/* torso */}
			<Cylinder args={[personTorsoRadius, personTorsoRadius, personTorsoHeight - 5 / 100, 32]} position={[0, personWaistHeight + personTorsoHeight / 2, 0]}>
				{personMaterial}
			</Cylinder>
			{/* head */}
			<Sphere args={[personHeadRadius, 32, 32]} position={[0, personWaistHeight + personTorsoHeight + personHeadRadius + 0 / 100, 0]}>
				{personMaterial}
			</Sphere>
		</group>
	)
}

export function gltfArea(gltf) {

	let area = 0

	gltf.scene.traverse((node) => {
		if (node.isMesh) {
			// console.log(node.geometry.index)
			// console.assert(node.geometry.attributes.position.count === nXyz)
			const xyzComponents = node.geometry.attributes.position.array
			const indices = node.geometry.index.array

			let meshArea = 0

			// each vertex is made from a sequence of three numbers for x, y and z. Step though the triples
			const vertices = []
			for (let i = 0; i < xyzComponents.length / 3; i++) {
				vertices.push(new Vector3(xyzComponents[i * 3], xyzComponents[i * 3 + 1], xyzComponents[i * 3 + 2]))
			}

			// Each triange is defined by three consecutive vertices in the vertex list.
			// The index list is also a set of triples pointing to the three vertices of the triangle (thus allowing reuse of vertices)
			let normal = new Vector3()
			for (let i = 0; i < indices.length / 3; i++) {
				let tri = new Triangle(vertices[indices[i * 3]], vertices[indices[i * 3 + 1]], vertices[indices[i * 3 + 2]])
				tri.getNormal(normal)
				let z=normal.z
				if ((!isEpsilon(z) && !isEpsilon(z-1) && !isEpsilon(z+1))) console.log('unusual normal (see isEpsilon)', z)
				if (isEpsilon(z - 1)) {
					meshArea += tri.getArea()
				}
			}
			// if (meshArea != 0) console.log(meshArea)
			area += meshArea
		}
	}
	)
	return area
}

function isEpsilon(number){ 
  return Math.abs(number) < 1e-3 // some triangles have a very slight lean towards z (maybe due to smoothing?).
} 

function gltfSize(gltf) {

	const box = new Box3().setFromObject(gltf.scene)
	const { max, min } = box
	// Y and Z are swapped in Three w.r.t. our GH coordinates; we'll work with the swapped axes throughout

	// However, we change Z axis positivity to match GH Y positivity, negative Z towards the camera, positive Z away

	// Note as of 8/10/2022. Since GH/Rhino 6/8/2022, the boundingBox inside the gltf is incorrect.
	// The problem appears only in the Y min/max values (Z coord in GH).
	// We work around it below by interating all vertices in all meshes to find the min/max Y values.
	let maxY = 0
	let minY = 1000
	gltf.scene.traverse((node) => {
		if (node.isMesh) {
			const vertexCount = node.geometry.attributes.position.count
			const positions = node.geometry.attributes.position.array  // vertices are in groups of 3: X,Y,Z
			for (let i = 0; i < vertexCount; i++) {
				let y = positions[(i * 3) + 1] // +1 is the Y value
				maxY = Math.max(maxY, y)
				minY = Math.min(minY, y)
			}
		}
	})
	maxY = Math.round(maxY*100) / 100  // 1 decimal place in centimeters

	const frontBottomLeft = new Vector3(Math.min(min.x, max.x), minY, Math.min(-min.z, -max.z))
	const backTopRight = new Vector3(Math.max(min.x, max.x), maxY, Math.max(-min.z, -max.z))

	// Well, this is pretty weird. In GH, the brass tabs are made a little proud of the top (by .05CM) so they always
	// show, even with Draco compression. But that causes overall height (Y) to round up to an addition 0.1CM.
	// So, we correct height here, which is in meters at this point. We might be able to do this more elegantly by removing
	// all of the brass tab meshes from the gltf before doing height calculations, but that code would not be simple.
	// So, for now we have some minor, inherent knowledge of how the gltf is created in Rhino; not great.
	backTopRight.setY(backTopRight.y - .05/100)

	// calc the size by subtracting the front-bottom-left corner from the back-top-right corner
	const size = new Vector3()
	size.copy(backTopRight)
	size.sub(frontBottomLeft)

	// calc some other useful values
	const maxLen = Math.max(size.x, size.y, size.z)
	// const avgLenHt = (size.x + size.y) / 2
	const center = new Vector3()
	center.lerpVectors(frontBottomLeft, backTopRight, 0.5)
	center.setZ(-center.z)

	// useful vectors for each axis, with length equal to the size along the axis
	const vX = new Vector3(size.x, 0, 0)
	const vY = new Vector3(0, size.y, 0)
	const vZ = new Vector3(0, 0, -size.z)
	
	// console.log('frontBottomLeft', frontBottomLeft, '\nbackTopRight', backTopRight, '\nsize', size, '\ncenter', center, '\nmaxLen', maxLen)
	return { frontBottomLeft, backTopRight, size, center, maxLen, /* avgLenHt, */ vX, vY, vZ }
}

const dimensionsMaterial = <lineBasicMaterial attach="material" color={'#ccc'} linewidth={1} />
export function dimensionsDisplay(gltf, productId, showDimensions) {
	// console.log('showDimensions', showDimensions)

	if (!showDimensions) return null

	const {frontBottomLeft, size, vX, vY, vZ} = gltfSize(gltf)

	return (
		<group position={frontBottomLeft}>
			<line geometry={line(vX)}>{dimensionsMaterial}</line>
			<line geometry={line(vY)}>{dimensionsMaterial}</line>
			<line geometry={line(vZ)}>{dimensionsMaterial}</line>
			{text(vX, size.x, 'bottom')}
			{text(vY, size.y, 'center')}
			{text(vZ, size.z, 'right')}
			<line geometry={arrowhead(vX)}>{dimensionsMaterial}</line>
			<line geometry={arrowhead(vY)}>{dimensionsMaterial}</line>
			<line geometry={arrowhead(vZ)}>{dimensionsMaterial}</line>
		</group>
	)
}

function line(vec3) {
	const points = [new Vector3(0, 0, 0), vec3]
  return new BufferGeometry().setFromPoints(points)
}

function arrowhead(position) {
	const length = 1
	const width = .2
	const scale = .05

	const points = []
	points.push(new Vector3(0, 0, 0))
	points.push(new Vector3(-length, width, 0))
	points.push(new Vector3(-length/2, 0, 0))
	points.push(new Vector3(-length/2, 0, 0))
	points.push(new Vector3(-length, -width, 0))
	points.push(new Vector3(0, 0, 0))

	// transform the points
	points.map(p => p.multiplyScalar(scale))
	// rotate Y and Z as necessary
	if (position.z < 0) {points.map(p => p.applyAxisAngle(new Vector3(0, 1, 0), Math.PI/2))}
	if (position.y > 0 || position.z < 0) {points.map(p => p.applyAxisAngle(new Vector3(0, 0, 1), Math.PI/2))}
	points.map(p => p.add(position))  // translate

	const arrow = new BufferGeometry().setFromPoints(points)
	return (arrow)
}

function text(position, value, anchorX) {
	return (
		<Text position={position} anchorX={anchorX} anchorY='bottom' fontSize={.03} color='black' lineHeight={.7}>
			{Math.round(value.toString() * 1000) / 10}cm
		</Text>
	)
}
