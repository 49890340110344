import { GlobalStateContext, ParamContext } from '../App'

import { useEffect, useState, useReducer, useContext } from 'react'
import styled from 'styled-components' // for animation speed and tooltip properties
import { Collapse } from 'react-collapse'
// import ReactTooltip from 'react-tooltip'
import { useSwipeable } from 'react-swipeable'
import { BsChevronDown, BsChevronUp, BsQuestionCircle, BsArrowCounterclockwise } from 'react-icons/bs'

import { localize } from './_localization'
import '../styles.css' // required for Expand/Collapse animation
import {
	isDT,
	isWebKit,
	theme,
	H3,
	H4,
	H5,
	colorParamText,
	colorSliderThumb,
	defaultBorderRadius,
	colorBackground,
	mobilePersonalizationBlockDiv_width_numeric,
} from './Global'

import { paramsUrl } from './_makeUrls'
import { toProperCase } from './_uiHelpers'
import { logParam, logVideo } from './_logging'

export default function ParamsBlock({ uiTree, productId, incomingParams, setParamsHeightChanged }) {
	// console.log('Param..Block', productId)
	const { globalState, setGlobalStateItem } = useContext(GlobalStateContext)
	const { currentParams, setCurrentParams } = useContext(ParamContext) // this is the 'setter' to send the inputParamState to MainConfiguratorBlock, where Rhino is called

	const [presetParams, setPresetParams] = useState() // these are the preset params that come from cfgserver
	const [visibleGroup, setVisibleGroup] = useState() // param GROUP's are shown one at a time
	const [visibleParam, setVisibleParam] = useState() // individual PARAM's are shown one at a time on mobile; all on desktop

	// the official state of the current parameters
	const [inputParamState, dispatch] = useReducer(reducer, {}) // the actual set of params is derived from getParams

	// update the current parameter state with a new value for a parameter (action.type:action.data is the key:value pair here)
	function reducer(state, action) {
		return {
			...state,
			[action.type]: action.data,
		}
	}

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// block initialization
	useEffect(() => {
		// console.log('Param..1', productId)

		// get the preset params for the productId
		const url = paramsUrl(productId)
		fetch(url)
			.then((response) => response.text())
			.then((params) => setPresetParams(params))

		showOneGroup(uiTree.groups[0]) // show the first param in the first param group
		// eslint-disable-next-line
	}, [productId])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// it's hard to know how to interpret parameter change events in order to trigger re-Rhino; this does it explicitly.  TODO: look at material-ui
	const [commitChanges, setCommitChanges] = useState(false)
	useEffect(() => {
		// console.log('Param..2', inputParamState)

		if (commitChanges) setCurrentParams(inputParamState) // send new parameters back to MainConfiguratorBlock
		setCommitChanges(false)
		// eslint-disable-next-line
	}, [commitChanges])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// create the initial inputParamState from ProductId presetParams and incoming params from the url
	useEffect(() => {
		if (presetParams === undefined) return
		// console.log('Param..3', presetParams, incomingParams)

		// create the initial state for all params in useReducer.
		// Include presetParams even if there in no matching ui param; this will thus record the user's full spec even if preset params change in the future
		new URLSearchParams(presetParams).forEach((value, key) => setParamValue(key, value))
		// override the preset parameters with the incoming parameters
		Object.entries(incomingParams).forEach(([key, value]) => setParamValue(key, value))

		setCommitChanges(true)
	}, [presetParams, incomingParams])

	// if currentParams are set elsewhere, e.g., BlockPersonalization, update inputParamState
	useEffect(() => {
		// console.log('Param..4', currentParams)

		Object.entries(currentParams).forEach((entry) => setParamValue(entry[0], entry[1]))
		// don't setCommitChanges, which would cause an infinite loop; setCommitChanges will happen on the next local param change
	}, [currentParams])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// detect a language change and re-build the block, and possibly the container
	useEffect(() => {
		// console.log('Param..5', language)
		setParamsHeightChanged(true) // trigger a re-render of the ParamsBlock (and possibly other things that depend on its size)
		// eslint-disable-next-line
	}, [globalState])

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

	// set a parameter value
	function setParamValue(rhinoName, value) {
		// console.log(rhinoName, value)
		dispatch({ type: rhinoName, data: value })
	}

	// show a parameter group by opening one and collapsing the others
	function showOneGroup(group) {
		// ReactTooltip.hide()
		// NOTE: if the toggle functionality is removed in the future then the presence of the up arrow must be
		// accounted for in the size of the group header. The collapse effect is jerky if the collapsed header is
		// not exactly the same size as the uncollapsed header.
		const newGroup = visibleGroup === group.groupCode ? null : group.groupCode // turn all of if the currently visible group is collapse explititly
		setVisibleGroup(newGroup)
		showOneParam(newGroup !== null ? group.params[0] : null)
		setParamsHeightChanged(true)
	}

	// in mobile, show the slider/selector for the selected param
	function showOneParam(param) {
		// ReactTooltip.hide()
		setVisibleParam(param?.rhinoName)
	}

	function parameterCommitted() {
		setCommitChanges(true) // send the changed parameter, along with all unchanged parameters, back to MainConfiguratorBlock
	}

	function commitParam(rhinoName, value) {
		// ReactTooltip.hide()
		// console.log('commit', rhinoName, value)
		setParamValue(rhinoName, value)
		parameterCommitted()
		logParam(productId, rhinoName)
	}

	function showParamVideo(param) {
		setGlobalStateItem('videoFilename', param.video)
		setGlobalStateItem('videoTitle', localize(param.name, globalState.language))
		setGlobalStateItem('showVideoModal', true)
		logVideo(productId, param.rhinoName)
	}

	function presetValue(param) {
		return new URLSearchParams(presetParams).get(param.rhinoName)
	}

	function resetParam(param) {
		commitParam(param.rhinoName, presetValue(param))
	}

	function isSlider(param) {
		return param.keyvaluepairs === '-'
	}

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

	const videoButtonHeightMobile = '12px'

	const Param = ({ param, commitParam }) => {
		// console.log('param', param)
		return (
			<div key={param.name}>
				<ParamContainerDiv key='ParamContainerDiv'>
					<ParamTextIconContainerDiv key='ParamTextIconContainerDiv'>
						<ParamIconContainerDiv>
							{isDT && param.video ? (
								<ParamVideoButton onClick={() => showParamVideo(param)}>
									{' '}
									<BsQuestionCircle />{' '}
								</ParamVideoButton>
							) : (
								<div></div>
							)}
						</ParamIconContainerDiv>
						{isDT ? (
							// just show the text for desktop
							<ParamText>{localize(param.name, globalState.language)}</ParamText>
						) : (
							// for mobile, the param name is a button that activates the corresponding slider or selector in the set of overlapping 'absolute' div
							<InputButtonMobile
								key='InputButtonMobile'
								onClick={() => showOneParam(param)}
								style={{
									backgroundColor:
										param.rhinoName === visibleParam ? theme._uiBackgroundColor : theme._pageBackgroundColor,
								}}
							>
								<ParamTextMobile>{localize(param.name, globalState.language)}</ParamTextMobile>
							</InputButtonMobile>
						)}
						{!isDT && param.rhinoName === visibleParam && (
							<InputButtonMobileBridgeBackground key='InputButtonMobileBridgeBackground' />
						)}
					</ParamTextIconContainerDiv>
					{
						// for desktop show ALL of the sliders/selectors; for mobile, show input controls for only the VISIBLE param
						(isDT || param.rhinoName === visibleParam) && (
							<InputDiv key='InputDiv'>
								{isSlider(param) ? (
									<Slider param={param} initialValue={inputParamState[param.rhinoName]} commitParam={commitParam} />
								) : isDT ? (
									// dropdown for desktop
									<SelectorDropdown param={param} inputParamState={inputParamState} commitParam={commitParam} />
								) : (
									// buttons for mobile
									<SelectorButtons param={param} inputParamState={inputParamState} commitParam={commitParam} />
								)}
							</InputDiv>
						)
					}
					{isDT && isSlider(param) && (
						<ParamResetButton
							onClick={() => resetParam(param)}
							style={{ visibility: inputParamState[param.rhinoName] === presetValue(param) ? 'hidden' : 'visible' }}
						>
							{' '}
							<BsArrowCounterclockwise size='16px' />{' '}
						</ParamResetButton>
					)}
				</ParamContainerDiv>
			</div>
		)
	}

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	// we're putting all of the slider props in a function so they can be reused in DT and mobile
	// useSwipeable must be called from within a Component if the block is being .map-ped, so it doesn't work in here
	function sliderProperties(param, currentValue, setCurrentValue, isSliderReversed) {
		// The slider thumb and track are rendered differently on different browsers. In general, there are those that
		// don't implement WebKit specs fully, and those that do (at least for horizontal sliders).
		// As of now (7/21/22), the browsers that are known to work well are Chrome, Safari and Edge. Firefox not.
		let style = { opacity: globalState.isModelLoading ? 0.5 : 1 }
		if (isWebKit) style = { ...style, ...{ WebkitAppearance: 'none' } }
		if (isSliderReversed) style = { ...style, ...{ direction: 'rtl' } }

		return {
			type: 'range',
			min: param.min,
			max: param.max,
			step: param.step,
			id: param.rhinoName,
			value: currentValue,
			onChange: (e) => setCurrentValue(e.target.value),
			onMouseUp: () => commitParam(param.rhinoName, currentValue),
			style: style,
		}
	}

	// complicated slider in three variations: desktop-webkit, desktop-nonWebkit, mobile
	const Slider = ({ param, initialValue, commitParam }) => {
		const [currentValue, setCurrentValue] = useState(initialValue) // keep track of the slider value as the user slides it
		// check to see if the slider direction should be reversed; this works only on desktop
		const isReverse = param.rev === '*'
		const isSliderReversed = isReverse && isDT
		const sliderProps = sliderProperties(param, currentValue, setCurrentValue, isSliderReversed)

		// we need a special touch handler to detect 'finger up' for 'commit' handling identical to 'mouse up'
		// note: the current version of useSwipeable does not actually
		// handle onMouseUp here. Also, the advertised onTouchEnd
		// does not actually handle onTouchEnd. So, onTouchEndOrOnMouseUp it is.
		const touchHandler = useSwipeable({ onTouchEndOrOnMouseUp: () => commitParam(param.rhinoName, currentValue) })

		// the label on a slider can be either a dynamic value or a pair of directional guides at either end of the slider (e.g., THIN/THICK)
		const isLabelPair = param.unit.includes('/')
		const labelPair = localize(param.unit, globalState.language).split('/')
		// console.log(param.unit, isLabelPair, isReverse)

		// a value that changes with the slider, along with a 'units' indicator, is included in some sliders
		const valueText = Math.round(currentValue * 10) / 10
		const dynamicValueText = (
			<SliderValueText key='ValueText'>
				{valueText}
				{param.unit}
			</SliderValueText>
		)

		// the sliders for desktop and mobile are quite different.
		// The desktop slider is horizontal, appears to the right of the parameter name, has directional guides at either end, and is highly styled
		// The mobile slider is vertical, appears in the same screen location for ever parameter, has direction guides at the top and bottom, and is unstyled

		const idxA = 0
		const idxB = 1
		let textA = labelPair[idxA]
		if (isLabelPair) textA = toProperCase(textA)
		let textB = labelPair[idxB]
		if (isLabelPair) textB = toProperCase(textB)
		const guideA = <SliderLabel>{isLabelPair ? textA : ''}</SliderLabel> // at the left side of desktop, top of mobile
		const guideB = isLabelPair ? <SliderLabel>{textB}</SliderLabel> : dynamicValueText // at the right side of desktop, bottom of mobile

		return (
			<SliderAndValueContainer key='SliderAndValueContainer'>
				{/* a row above the slider to hold directional guides, e.g., THIN/THICK, or a dynamic value */}
				{!isDT && param.video ? (
					<ParamVideoButton
						onClick={() => showParamVideo(param)}
						style={{ height: videoButtonHeightMobile, margin: '4px 0 0 0' }}
					>
						<BsQuestionCircle height='20' />
					</ParamVideoButton>
				) : (
					<div style={{ height: videoButtonHeightMobile }}></div>
				)}
				<SliderLabelTopRow>
					{isDT && guideA}
					{!isDT && guideB}
					{isDT && guideB}
				</SliderLabelTopRow>

				{/* the actual DT (horizontal) or mobile (vertical) slider */}
				{isDT && <ParamSlider {...sliderProps} {...touchHandler} />}
				{!isDT && <ParamSliderMobile {...sliderProps} {...touchHandler} />}

				{/* a bottom row for mobile only */}
				{!isDT && <SliderLabelBottomRow>{guideA}</SliderLabelBottomRow>}

				{!isDT && (
					<ParamResetButton
						onClick={() => resetParam(param)}
						style={{ visibility: inputParamState[param.rhinoName] === presetValue(param) ? 'hidden' : 'visible' }}
					>
						{' '}
						<BsArrowCounterclockwise size='16px' />{' '}
					</ParamResetButton>
				)}
			</SliderAndValueContainer>
		)
	}

	const desktopButtons_ContainerStyles = {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'start',
		flexWrap: 'wrap',
		padding: '3px 0 3px 0',
		background: '#eee',
		borderRadius: '10px',
	}

	const desktopButton_ContainerStyles = {
		width: '33%',
	}

	const SelectorButtonsGroup = ({ param, inputParamState, commitParam }) => {
		return (
			<SelectorButtonsContainer
				key='SelectorButtonContainer'
				id={param.rhinoName}
				style={isDT ? desktopButtons_ContainerStyles : {}}
			>
				{param.keyvaluepairs.split(',').map((pair, index) => {
					const optionElements = pair.split('=')
					console.assert(optionElements.length > 1, 'Bad keyvaluepair (missing "=")', pair)
					const optionValue = optionElements[1].trim() // the option value that would be an item in a dropdown
					const optionText = optionElements[0].trim()
					const optionColor = optionElements.length > 2 ? optionElements[2] : ''
					// console.log(inputParamState[param.rhinoName], optionValue)
					return (
						<SelectorButtonContainer key={index} style={isDT ? desktopButton_ContainerStyles : {}}>
							<SelectorButton
								style={{
									borderStyle: inputParamState[param.rhinoName] === optionValue ? 'solid' : 'none',
									backgroundColor: optionColor,
								}}
								value={optionValue}
								{...{ onClick: (e) => commitParam(param.rhinoName, e.target.value) }}
							></SelectorButton>
							<SelectorButtonText>{localize(optionText, globalState.language)}</SelectorButtonText>
						</SelectorButtonContainer>
					)
				})}
			</SelectorButtonsContainer>
		)
	}

	// we have two styles of Selector: a typical DROPDOWN, and a sequnce of BUTTONS representing selector options
	const useButtonsOnDesktop = true
	const SelectorDropdown = ({ param, inputParamState, commitParam }) => {
		return useButtonsOnDesktop ? (
			<SelectorButtonsGroup param={param} inputParamState={inputParamState} commitParam={commitParam} />
		) : (
			<SelectorDropdownDiv
				key='SelectorDropdownDiv'
				id={param.rhinoName}
				{...{ onChange: (e) => commitParam(param.rhinoName, e.target.value) }}
				value={inputParamState[param.rhinoName]}
			>
				{param.keyvaluepairs.split(',').map((pair, index) => (
					<option key={index} value={pair.split('=')[1].trim()}>
						{localize(pair.split('=')[0].trim(), globalState.language)}
					</option>
				))}
			</SelectorDropdownDiv>
		)
	}

	// a sequnce of BUTTONS representing selector options
	const SelectorButtons = ({ param, inputParamState, commitParam }) => {
		return (
			<SelectorContainerVertical>
				{param.video ? (
					<ParamVideoButton
						onClick={() => showParamVideo(param)}
						style={{ height: videoButtonHeightMobile, margin: '4px 0 0 0' }}
					>
						<BsQuestionCircle height='20' />
					</ParamVideoButton>
				) : (
					<div style={{ height: videoButtonHeightMobile }}></div>
				)}
				<SelectorButtonsGroup param={param} inputParamState={inputParamState} commitParam={commitParam} />
			</SelectorContainerVertical>
		)
	}

	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
	return (
		<div>
			<MobileSwitchContainer key='MobileSwitchContainer'>
				<MainContainerDiv key='MainContainerDiv'>
					{/* {isDT && <ReactTooltip delayShow={500} />} */}
					<GroupsContainerDiv key='GroupsContainerDiv'>
						{uiTree.groups.map((group) => {
							const color = !isDT && group.groupCode === visibleGroup ? theme._uiTextColorInverse : theme._uiTextColor
							const backgroundColor =
								!isDT && group.groupCode === visibleGroup ? theme._uiBackgroundColorInverse : theme._uiBackgroundColor
							return (
								<GroupContainerDiv key={group.groupCode} style={{ backgroundColor: backgroundColor }}>
									<GroupControlDiv key='GroupControlDiv'>
										<GroupText key='GroupText' style={{ color: color }}>
											{localize(group.name, globalState.language)}
										</GroupText>
										<ExpandButtonComponent key='ExpandButtonComponent' onClick={() => showOneGroup(group)}>
											<ArrowSVGDiv key='ArrowSVGDiv'>
												{group.groupCode === visibleGroup ? (
													<BsChevronUp size={theme.IconChevron_size} style={{ color: color }} />
												) : (
													<BsChevronDown size={theme.IconChevron_size} style={{ color: color }} />
												)}
											</ArrowSVGDiv>
										</ExpandButtonComponent>
									</GroupControlDiv>
									<Collapse isOpened={group.groupCode === visibleGroup}>
										<GroupSeparatorDiv key='GroupSeparatorDiv'>
											<GroupParamsContainerDiv key='GroupParamsContainerDiv'>
												{group.params.map((param) => (
													<Param param={param} commitParam={commitParam} dispatch={dispatch} key={param.name} />
												))}
											</GroupParamsContainerDiv>
										</GroupSeparatorDiv>
									</Collapse>
								</GroupContainerDiv>
							)
						})}
					</GroupsContainerDiv>
				</MainContainerDiv>
				<MobileVerticalControlBackground />
			</MobileSwitchContainer>
		</div>
	)
}
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
//0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000

// const textIndent = "30px;"

const MobileSwitchContainer = styled.div`
	position: relative;
	display: ${(props) => props.theme.MobileSwitchContainer_display};
	flex-direction: row;
`

const MobileVerticalControlBackground = styled.div`
	width: ${(props) => props.theme.MobileVerticalControlBackground_width};
	background-color: white;
	margin: 4px;
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
	border-radius: ${(props) => props.theme.GroupControlDiv_borderRadius};
`

// position at the right side, full hieght
const MainContainerDiv = styled.div`
	width: ${(props) => props.theme.MainContainerDiv_width};
	display: flex;
	flex-direction: column;
	// background-color: white;
`

const GroupsContainerDiv = styled.div`
	// padding-bottom: 0px;
	// padding-right: 0px;
	// border-style: solid;
	// border-top: 0;
	// border-left: 0;
	// border-right: 0;
	// border-bottom-style: solid;
	// border-bottom-width: thin;
`

const GroupSeparatorDiv = styled.div`
	display: flex;
	flex-direction: row;
`

const GroupControlDiv = styled.div`
	display: flex;
	flex-direction: ${(props) => props.theme.GroupControlDiv_flexDirection};
	justify-content: space-between;
	padding: ${(props) => props.theme.GroupControlDiv_padding};
`

const GroupParamsContainerDiv = styled.div`
	width: 100%;
	padding: ${(props) => props.theme.GroupParamsContainerDiv_padding};
`

// each parameter group
const GroupContainerDiv = styled.div`
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
	margin: ${(props) => props.theme.GroupContainerDiv_margin};
	border-radius: ${(props) => props.theme.GroupControlDiv_borderRadius};
`

const GroupText = styled(H3)`
	// text-transform: capitalize;
	align-self: center;
`

// the container for a parameter
const ParamContainerDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${(props) => props.theme.ParamContainerDiv_justifyContent};
	margin: ${(props) => props.theme.ParamContainerDiv_margin};
`

// a flexbox to contain the parameter icon and text in a single row
const ParamTextIconContainerDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${(props) => props.theme.ParamTextIconContainerDiv_justifyContent};
	width: ${(props) => props.theme.ParamTextIconContainerDiv_width};
	height: 90%;
	margin: -3px 0 3px 0;
`

const ParamIconContainerDiv = styled.div`
	width: ${(props) => props.theme.ParamIconContainerDiv_width};
	margin: ${(props) => props.theme.ParamIconContainerDiv_margin};
`

// the parameter name
const ParamText = styled(H4)`
	margin: 0;
	margin-right: 10px;
	text-align: left;
	color: ${colorParamText};
	// text-transform: capitalize;
`

// the slider value
const SliderValueText = styled(H5)`
	margin: ${(props) => props.theme.SliderValueText_margin};
	color: ${colorParamText};
`

const InputDiv = styled.div`
	width: ${(props) => props.theme.InputDiv_width};
	margin-bottom: 25px;
`

const InputButtonMobile = styled.button`
	width: 90%;
	height: 22px;
	margin: 3px 0 3px 0;
	border-width: 0;
	border-radius: ${defaultBorderRadius * 0.5}px;
	background-color: ${colorBackground};
`

const InputButtonMobileBridgeBackground = styled.div`
	position: absolute;
	left: -5px;
	width: 14px;
	height: 22px;
	margin: 3px 0 0 70px;
	background-color: ${(props) => props.theme._uiBackgroundColor};
`

const ParamTextMobile = styled(H4)`
	text-align: center;
	color: ${(props) => props.theme._uiTextColor};
	// text-transform: capitalize;
`

// parameter dropdown
const SelectorDropdownDiv = styled.select`
	width: 88%;
	height: 20px;
	margin: ${(props) => props.theme.SelectorDropdownDiv_margin};
	background: #ddd;
	// box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
	color: black;
	border: none;
	font-family: 'Sharp Sans No1 Medium';
	font-style: normal;
	// font-weight: 800;
	font-size: ${(props) => props.theme.SelectorDropdownDiv_fontSize};
	// text-transform: capitalize;
	&:focus {
		outline: none;
	}
`

// this holds the slider and the value text. In mobile it is an 'absolute' vertical div. In desktop it is a normal, horizontal div
const SliderAndValueContainer = styled.div`
	position: ${(props) => props.theme.SliderAndValueContainer_position};
	width: ${(props) => props.theme.MobileVerticalControlBackground_width};
	top: 6px; // needed only for mobile, which has position:absolute
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: ${(props) => props.theme.SliderAndValueContainer_alignItems};
`

const SliderLabelTopRow = styled.div`
	margin: ${(props) => props.theme.SliderLabelTopRow_margin};
	display: flex;
	width: 100%;
	justify-content: ${(props) => props.theme.SliderLabelTopRow_justifyContent};
`

const SliderLabelBottomRow = styled.div`
	display: flex;
	width: 100%;
	// margin: 0 0 10px 0;
	justify-content: center;
`

const SliderLabel = styled(H5)`
	// text-transform: capitalize;  // doesn't work here
`

const SelectorContainerVertical = styled(SliderAndValueContainer)``

const SelectorButtonsContainer = styled(SliderAndValueContainer)`
	top: 20px; // room for video button
	bottom: 4px;
	width: ${mobilePersonalizationBlockDiv_width_numeric / 2}px;
	justify-content: space-evenly;
`

const SelectorButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: ${(props) => props.theme.SelectorDropdownDiv_fontSize};
	// for Safari
	-webkit-box-align: center;
	text-align: center;
`

const selectorButtonSize = 30
const SelectorButton = styled.button`
	width: ${selectorButtonSize}px;
	height: ${selectorButtonSize * 1.0}px;
	background-color: #ddd;
	border-radius: ${selectorButtonSize / 2}px;
	border-width: thin;
	border-style: none; // change to "solid" for currently selected option
	border-color: black;
`

const SelectorButtonText = styled(H4)``

// variables for styling the input slider
const height = '2px'
const thumbHeight = '12px'
const thumbWidth = '30px'

// colours
const leftColor = '#888'
// const rightColor = "#0c0";
// const leftColor = "#00f";
const thumbColor = colorSliderThumb
const thumbHoverColor = '#ccc'
const thumbFocusColor = '#000' // when focused, the keyboard can be used to adjust the value
const lowerBackground = leftColor
// const lowerBackground = `linear-gradient(to right, ${leftColor}, ${rightColor} 55%) `;

// complicated styling for the slider
const ParamSlider = styled.input`
overflow: visible;
display: block;
width: ${(props) => props.theme.ParamSlider_width};
margin: ${(props) => props.theme.ParamSlider_margin};
height: ${(props) => props.theme.ParamSlider_height};

&:focus {
	outline: none;
}

&::-webkit-slider-runnable-track {
	width: 100%;
	height: ${height};
	background: ${lowerBackground};
	border-radius: 0;
}

&::-webkit-slider-thumb {
	position: relative;
	appearance: none;
	height: ${thumbHeight};
	width: ${thumbWidth};
	background: ${thumbColor};
	border-radius: 20%;
	border: 0;
	top: 50%;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
	transform: translateY(-50%);
	transition: background-color 150ms;
}


&:focus {
	&::-webkit-slider-thumb {
		background-color: ${thumbFocusColor};
	}
}
&:hover {
	&::-webkit-slider-thumb {
		background-color: ${thumbHoverColor};
	}
`

const ParamSliderMobile = styled.input`
	appearance: slider-vertical;
	width: 2px;
	height: 70%;
	accent-color: ${thumbColor};
`

const ExpandButton = styled.button`
	height: 100%;
	border-radius: 0;
	border-width: 0;
	background-color: transparent;
	margin: ${(props) => props.theme.ExpandButton_margin};
`

export const ExpandButtonComponent = ({ onClick, children }) => {
	return <ExpandButton onClick={onClick}>{children}</ExpandButton>
}

const ArrowSVGDiv = styled.div`
	margin: ${(props) => props.theme.ArrowSVGDiv_margin};
`

const ParamVideoButton = styled.button`
	padding: 0;
	border-radius: 0;
	border-width: 0;
	background-color: transparent;
`

const ParamResetButton = styled.button`
	margin: ${(props) => props.theme.ParamResetButton_margin};
	padding: 0;
	border-radius: 0;
	border-width: 0;
	background-color: transparent;
	color: #ffa50088; // light orange
`
